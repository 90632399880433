const germanTranslation = {
  "About Us": "Über uns",
  Account: "Konto",
  "Add new Address": "Neue Adresse hinzufügen",
  "Address Line 1": "Anschrift Zeile 1",
  "Address Line 2": "Adresszeile 2",
  "Cart total": "Wagen insgesamt",
  City: "Stadt",
  Company: "Unternehmen",
  "Congratulation!": "Glückwunsch!",
  "Continue shopping": "Mit dem Einkaufen fortfahren",
  Country: "Land",
  "Customer-ID:": "Kundennummer:",
  Email: "Email",
  "First name": "Vorname",
  "Forgot your password?": "Haben Sie Ihr Passwort vergessen?",
  "Haven't ordered with us before?": "Noch nicht bei uns bestellt?",
  "Help Center": "Hilfezentrum",
  Impressum: "Impressum",
  "Invoice address": "Rechnungsanschrift",
  "Invoice address is identical to Shipping address":
    "Die Rechnungsadresse ist identisch mit der Lieferadresse",
  "Last Name": "Familienname, Nachname",
  Login: "Anmeldung",
  Logout: "Ausloggen",
  "My Account": "Mein Konto",
  "Order review": "Bestellüberprüfung",
  "PRODUCT OVERVIEW QUADRIFID": "PRODUKTÜBERSICHT QUADRIFID",
  Payment: "Zahlung",
  "Payment Method": "Bezahlverfahren",
  Phone: "Telefon",
  "Postal code": "Postleitzahl",
  Products: "Produkte",
  Register: "Registrieren",
  Search: "Suche",
  Service: "Bedienung",
  Shipping: "Versand",
  "Shipping address": "Lieferanschrift",
  "Social Media": "Sozialen Medien",
  Subtotal: "Zwischensumme",
  "Tax Rate": "Steuersatz",
  "Tel:": "Tel",
  "Terms and Conditions": "Geschäftsbedingungen",
  Total: "Gesamt",
  "You have successfully completed your purchase.":
    "Sie haben Ihren Kauf erfolgreich abgeschlossen.",
  "Your Account": "Ihr Konto",
  "Your Order-No:": "Ihre Bestellnummer:",
  "Your Profile": "Dein Profil",
};

export default germanTranslation;

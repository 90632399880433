import _get from "lodash.get";
import React from "react";
import Hooks from "../hooks";
import PaymentImage from "../images/footer-payment.png";
import ShippingImage from "../images/footer-shipping.png";
import { Link } from "react-router-dom";
import { useLocaleContext } from "../context/localeContext";
import { LabelKeys } from "../config/locale/label-config";
import { TT } from "../config/locale";
const Footer = () => {
  const { locale } = useLocaleContext();
  let { footerContact, footerSocialMedia, logoUrl } = Hooks.useFooter(locale);
  return (
    <div className="footer mt-2">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-3">
            <div className="footer-logo">
              <a href="/" rel="noreferrer" target="_self">
                <img
                  height="60px"
                  width="120px"
                  alt="logo"
                  style={{ marginBottom: "20px" }}
                  src={logoUrl}
                />
              </a>
            </div>
            <address className="footer-address">
              <span>
                {_get(footerContact, "addressLine1")}{" "}
                {_get(footerContact, "addressLine2")}
              </span>
              <span>
                {_get(footerContact, "city")} {_get(footerContact, "country")}
              </span>
              <span>{_get(footerContact, "zipCode")}</span>
              <span className="mt-3">
                {_get(footerContact, "phoneNumber") && (
                  <span>
                    {TT(LabelKeys.FOOTER_TEL, locale)} +
                    {_get(footerContact, "countryCode")}-
                    {_get(footerContact, "phoneNumber")}
                  </span>
                )}
              </span>
              <span>{_get(footerContact, "emailId")}</span>
            </address>
          </div>
          <div className="col-12 col-sm-3">
            <div className="footer-nav mb-5">
              <h6 className="footer-nav-title">
                {TT(LabelKeys.FOOTER_SERVICE, locale)}
              </h6>
              <li>
                <Link to="/privacy">
                  {" "}
                  {TT(LabelKeys.FOOTER_IMPRESSUM, locale)}
                </Link>
              </li>
              <li>
                <Link to="/terms"> {TT(LabelKeys.FOOTER_T_N_C, locale)}</Link>
              </li>
              <li>
                <a href="#"> {TT(LabelKeys.FOOTER_HELP_CENTER, locale)}</a>
              </li>
              <li>
                <a href="#"> {TT(LabelKeys.FOOTER_ABOUT_US, locale)}</a>
              </li>
            </div>
            <div className="footer-nav">
              <h6 className="footer-nav-title">
                {" "}
                {TT(LabelKeys.FOOTER_ACCOUNT, locale)}
              </h6>
              <li>
                <a href="/order-history.html">
                  {" "}
                  {TT(LabelKeys.FOOTER_MY_ACCOUNT, locale)}
                </a>
              </li>
              <li>
                <a href="/address.html">
                  {" "}
                  {TT(LabelKeys.FOOTER_REGISTER, locale)}
                </a>
              </li>
              <li>
                <a href="/login.html"> {TT(LabelKeys.FOOTER_LOGIN, locale)}</a>
              </li>
            </div>
          </div>
          <div className="col-12 col-sm-3">
            <div className="footer-nav">
              <h6 className="footer-nav-title">
                {" "}
                {TT(LabelKeys.FOOTER_PAYMENT, locale)}
              </h6>
              <div className="footer-img-set">
                <img src={PaymentImage} className="w-75" alt="pay" />
              </div>
            </div>
            <div className="footer-nav">
              <h6 className="footer-nav-title">
                {" "}
                {TT(LabelKeys.FOOTER_SOCIAL_MEDIA, locale)}
              </h6>
              <div className="social-nav">
                {footerSocialMedia &&
                  footerSocialMedia.map((item) => {
                    return (
                      <a
                        href={_get(item, "socialMediaPageLink")}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          alt={_get(item, "socialMediaName")}
                          style={{
                            height: "32px",
                            width: "32px",
                            marginLeft: "10px",
                          }}
                          src={_get(item, "socialMediaLogo.url")}
                        />
                      </a>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-3">
            <div className="footer-nav">
              <h6 className="footer-nav-title">
                {" "}
                {TT(LabelKeys.FOOTER_SHIPPING, locale)}
              </h6>
              <div className="footer-img-set">
                <img src={ShippingImage} className="w-75" alt="shipping" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

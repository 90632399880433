export const LabelKeys = {
  /** HOMEPAGE Values */
  HOME_PAGE_PRODUCT: "PRODUCT OVERVIEW QUADRIFID",

  /** Address Labels */
  ADDRESS_SHIPPING: "Shipping address",
  ADDRESS_INVOICE: "Invoice address",

  ADDRESS_FIELD_COMPANY: "Company",
  ADDRESS_FIELD_FIRST_NAME: "First name",
  ADDRESS_FIELD_LAST_NAME: "Last Name",
  ADDRESS_FIELD_LINE_1: "Address Line 1",
  ADDRESS_FIELD_LINE_2: "Address Line 2",
  ADDRESS_FIELD_CITY: "City",
  ADDRESS_FIELD_POSTAL_CODE: "Postal code",
  ADDRESS_FIELD_COUNTRY: "Country",
  ADDRESS_FIELD_PHONE: "Phone",
  ADDRESS_FIELD_EMAIL: "Email",
  /** Cart page labels */
  CART_ADDRESS_IDENTICAL: "Invoice address is identical to Shipping address",
  CART_ADD_NEW: "Add new Address",

  CART_TOTAL_HEADING: "Cart total",
  CART_SUBTOTAL: "Subtotal",
  CART_TAX_RATE: "Tax Rate",
  CART_TOTAL: "Total",
  CART_PAYMENT_METHOD: "Payment Method",

  HEADER_PRODUCTS: "Products",
  HEADER_SEARCH: "Search",
  HEADER_LOGIN: "Login",
  HEADER_REGISTER: "Register",
  HEADER_REGISTER_SUB_TEXT: "Haven't ordered with us before?",
  HEADER_FORGOT_PASSWORD: "Forgot your password?",
  HEADER_ACCOUNT: "Your Account",
  HEADER_CUSTOMER_ID: "Customer-ID:",
  HEADER_PROFILE: "Your Profile",
  HEADER_ORDER_REVIEW: "Order review",
  HEADER_LOGOUT: "Logout",

  FOOTER_TEL: "Tel:",
  FOOTER_SERVICE: "Service",
  FOOTER_IMPRESSUM: "Impressum",
  FOOTER_T_N_C: "Terms and Conditions",
  FOOTER_HELP_CENTER: "Help Center",
  FOOTER_ABOUT_US: "About Us",
  FOOTER_ACCOUNT: "Account",
  FOOTER_MY_ACCOUNT: "My Account",
  FOOTER_REGISTER: "Register",
  FOOTER_LOGIN: "Login",
  FOOTER_PAYMENT: "Payment",
  FOOTER_SOCIAL_MEDIA: "Social Media",
  FOOTER_SHIPPING: "Shipping",

  CHECKOUT_SUCCESS_MSG_1: "Congratulation!",
  CHECKOUT_SUCCESS_MSG_2: "You have successfully completed your purchase.",
  CHECKOUT_ORDER_NO: "Your Order-No:",
  CHECKOUT_CONTINUE_SHOPPING: "Continue shopping",
};
